import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Headshots from "../assets/eli_and_ravneet.jpg"
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import firebase from "./../helpers/Firestore";

const remoteConfig = firebase.remoteConfig();

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: "bold",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
  },
  centerText: {
    textAlign: "center"
  },
  boldUnderlineText: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    textAlign: "center",
    fontSize: "12px"
  },
  button: {
    width: "275px"
  },
}));

const Contact = (props) => {
  const classes = useStyles();
  return (
    <div>
      <div className="line" />
      <div className="flex-container">
        <Typography
          className={classes.headerText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('contact_header_text').asString()}
        </Typography>
      </div>
      <div className="flex-container">

      </div>
      <div className="flex-container">
        <Typography component="p" variant="inherit" dangerouslySetInnerHTML={{ __html: remoteConfig.getValue('output_footer_text').asString() }} />
      </div>
      <div className="flex-container">
        <TextField
          id="outlined-search"
          label="Name"
          type="search"
          variant="outlined"
          value={props.name}
          onChange={(event) => props.handleSetName(event)}
        />
      </div>
      <div className="flex-container">
        <TextField
          id="outlined-search"
          label="Email"
          type="search"
          variant="outlined"
          value={props.email}
          onChange={(event) => props.handleSetEmail(event)}
          onFocus={() => props.clearEmailError()}
          error={props.emailError !== ""}
          helperText={props.emailError}
        />
      </div>
      <div className="flex-container">
        <Button
          className={classes.button}
          onClick={() => props.handleSendEmail()}
          variant="contained"
          color="secondary"

        >
          {!props.emailLoading ? remoteConfig.getValue('button_email').asString() : <CircularProgress size={30} color="black" />}
        </Button>
      </div>
    </div>
  );
};

export default Contact;
