import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  calcSellingLegalFees,
  calcSellingPostage,
  calcSellingEFTFees,
  calcSellingTotal,
  calcIDVerificationFee,
  calcSellingLandTitleSearchFees,
  currencyFormat,
  rangeFormat
} from "../helpers/Calculations.js";
import firebase from "./../helpers/Firestore";

const remoteConfig = firebase.remoteConfig();

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: "bold",
  },
}));

const SellingOutput = (props) => {
  const classes = useStyles();
  const { price, sellers, sellerCharges, sellingMortgage } = props;
  return (
    <div className="container">
      {/* Insurance */}
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
        {remoteConfig.getValue('output_selling_price').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(price)}
        </Typography>
      </div>
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_selling_legal_fees').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcSellingLegalFees(price, sellers, sellerCharges, sellingMortgage))}
        </Typography>
      </div>
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_selling_disbursements').asString()}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
        {remoteConfig.getValue('output_selling_postage_printing').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcSellingPostage(sellerCharges, sellingMortgage))}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
        {remoteConfig.getValue('output_selling_eft_fees').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcSellingEFTFees())}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_selling_id_verification_fee').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcIDVerificationFee(sellers))}
        </Typography>
      </div>
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_selling_government_fee').asString()}
        </Typography>
      </div>

      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_selling_land_title_search_fees').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcSellingLandTitleSearchFees())}
        </Typography>
      </div>

      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_selling_total_price_of_conveyance').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcSellingTotal(price, sellers, sellerCharges, sellingMortgage))}
        </Typography>
      </div>
      <div className="flex-container">
        <Button
          onClick={props.handleCalculateAgain}
          variant="contained"
          color="secondary"
        >
          {remoteConfig.getValue('button_pac').asString()}
        </Button>
      </div>
    </div>
  );
};

export default SellingOutput;