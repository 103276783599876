export const municipalities = [
  { name: "Abbotsford", fees: 105.05 },
  { name: "Anmore", fees: 120.05 },
  { name: "Armstrong", fees: 107.05 },
  { name: "Burnaby", fees: 136.55 },
  { name: "Campbell River", fees: 115.05 },
  { name: "Castlegar", fees: 110.05 },
  { name: "Central Saanich", fees: 115.05 },
  { name: "Chase", fees: 120.05 },
  { name: "Chetwynd", fees: 95.05 },
  { name: "Chilliwack", fees: 115.05 },
  { name: "Clearwater", fees: 110.05 },
  { name: "Coldstream", fees: 110.05 },
  { name: "Colwood", fees: 115.05 },
  { name: "Comox", fees: 100.05 },
  { name: "Coquitlam", fees: 120.05 },
  { name: "Courtenay", fees: 95.05 },
  { name: "Cranbrook", fees: 110.05 },
  { name: "Creston", fees: 130.05 },
  { name: "Dawson Creek", fees: 95.92 },
  { name: "Delta", fees: 120.05 },
  { name: "Duncan", fees: 100.05 },
  { name: "Enderby", fees: 100.05 },
  { name: "Esquimalt", fees: 115.05 },
  { name: "Fernie", fees: 95.05 },
  { name: "Fort St. James", fees: 105.05 },
  { name: "Fort St. John", fees: 100.05 },
  { name: "Fraser Lake", fees: 105.05 },
  { name: "Gibsons", fees: 105.05 },
  { name: "Gold River", fees: 115.05 },
  { name: "Golden", fees: 105.05 },
  { name: "Grand Forks", fees: 110.05 },
  { name: "Hope", fees: 110.05 },
  { name: "Kamloops", fees: 105.05 },
  { name: "Kaslo", fees: 97.05 },
  { name: "Kelowna", fees: 100.05 },
  { name: "Kent", fees: 110.05 },
  { name: "Kimberley", fees: 100.05 },
  { name: "Kitimat", fees: 90.05 },
  { name: "Ladysmith", fees: 105.05 },
  { name: "Lake Country", fees: 110.05 },
  { name: "Lake Cowichan", fees: 115.05 },
  { name: "Langford", fees: 112.55 },
  { name: "Langley (City)", fees: 125.05 },
  { name: "Langley (District)", fees: 125.05 },
  { name: "Lantzville", fees: 120.05 },
  { name: "Lillooet", fees: 110.05 },
  { name: "Lions Bay", fees: 115.05 },
  { name: "Logan Lake", fees: 105.05 },
  { name: "Lumby", fees: 110.05 },
  { name: "Lytton", fees: 116.05 },
  { name: "Maple Ridge", fees: 120.05 },
  { name: "Merritt", fees: 124.05 },
  { name: "Metchosin", fees: 100.05 },
  { name: "Mission", fees: 115.05 },
  { name: "Nanaimo", fees: 93.05 },
  { name: "Nelson", fees: 115.05 },
  { name: "New Westminster", fees: 123.05 },
  { name: "North Cowichan", fees: 105.05 },
  { name: "North Saanich", fees: 115.05 },
  { name: "North Vancouver", fees: 115.05 },
  { name: "North Vancouver (District)", fees: 125.05 },
  { name: "Oak Bay", fees: 105.05 },
  { name: "Oliver", fees: 106.05 },
  { name: "Osoyoos", fees: 105.05 },
  { name: "Parksville", fees: 105.05 },
  { name: "Peachland", fees: 105.05 },
  { name: "Pemberton", fees: 105.05 },
  { name: "Penticton", fees: 95.55 },
  { name: "Pitt Meadows", fees: 115.05 },
  { name: "Port Alberni", fees: 105.05 },
  { name: "Port Coquitlam", fees: 125.05 },
  { name: "Port Hardy", fees: 110.05 },
  { name: "Port Moody", fees: 125.05 },
  { name: "Pouce Coupe", fees: 95.05 },
  { name: "Powell River", fees: 107.05 },
  { name: "Prince George", fees: 110.05 },
  { name: "Prince Rupert", fees: 110.05 },
  { name: "Qualicum Beach", fees: 100.05 },
  { name: "Quesnel", fees: 115.05 },
  { name: "Revelstoke", fees: 110.05 },
  { name: "Richmond", fees: 126.05 },
  { name: "Rossland", fees: 110.05 },
  { name: "Saanich", fees: 110.05 },
  { name: "Sechelt", fees: 110.05 },
  { name: "Sicamous", fees: 105.05 },
  { name: "Sidney", fees: 110.05 },
  { name: "Smithers", fees: 110.05 },
  { name: "Sooke", fees: 150.05 },
  { name: "Squamish", fees: 105.05 },
  { name: "Summerland", fees: 95.05 },
  { name: "Sun Peaks", fees: 105.05 },
  { name: "Surrey", fees: 126.80 },
  { name: "Surveyor of Taxes", fees: 91.88 },
  { name: "Taylor", fees: 105.05 },
  { name: "Telkwa", fees: 105.05 },
  { name: "Terrace", fees: 105.05 },
  { name: "Trail", fees: 100.05 },
  { name: "Tsawwassen First Nation", fees: 110.05 },
  { name: "Ucluelet", fees: 95.05 },
  { name: "Vancouver", fees: 135.05 },
  { name: "Vanderhoof", fees: 95.05 },
  { name: "Vernon", fees: 105.05 },
  { name: "Victoria", fees: 110.05 },
  { name: "View Royal", fees: 115.05 },
  { name: "West Kelowna", fees: 110.05 },
  { name: "West Vancouver", fees: 120.05 },
  { name: "Westbank First Nation", fees: 115.05 },
  { name: "Whistler", fees: 110.05 },
  { name: "White Rock", fees: 130.05 },
  { name: "Williams Lake", fees: 115.05 },
];
