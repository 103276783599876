import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  rangeFormat,
  currencyFormat,
  calcPTT,
  calcGST,
  calcLegalFees,
  calcTitleInsurance,
  calcInsuranceBinder,
  calcStrataFormsFee,
  calcSpecialitySoftwareFee,
  calcPostage,
  calcWireTransfer,
  calcLandTitleFormA,
  calcLandTitleFormB,
  calcLandTitleSearchFees,
  calcTaxCertificate,
  calcMortgageInstructionFee,
  calcTrustAdministrationFee,
  calcTotalClosingCosts,
  calcPriceOfConveyance,
  calcIDVerificationFee,
} from "../helpers/Calculations.js";
import Button from "@material-ui/core/Button";
import firebase from "./../helpers/Firestore";

const remoteConfig = firebase.remoteConfig();

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: "bold",
  },
}));

const Output = (props) => {
  const classes = useStyles();
  const { price, purchasers, municipality, mortgage, strata, firstTimeBuyer, newConstruction } = props;
  return (
    <div className="container">
      {/* Insurance */}
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
       {remoteConfig.getValue('output_buying_purchase_price').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(price)}
        </Typography>
      </div>
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_buying_ppt').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcPTT(price, firstTimeBuyer, newConstruction))}
        </Typography>
      </div>
      {newConstruction &&
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_buying_gst_purchase_price').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcGST(price, newConstruction))}
        </Typography>
      </div>
      }
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_buying_legal_fees').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcLegalFees(price, purchasers, mortgage, strata))}
        </Typography>
      </div>
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_buying_disbursements').asString()}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
        {remoteConfig.getValue('output_buying_title_insurance').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcTitleInsurance(price))}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_buying_insurance_binder').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcInsuranceBinder())}
        </Typography>
      </div>
      {strata &&
        <div className="output-body-container">
          <Typography component="p" variant="inherit">
            {remoteConfig.getValue('output_buying_strata_forms_fee').asString()}
          </Typography>
          <Typography component="p" variant="inherit">
            {rangeFormat(calcStrataFormsFee(strata))}
          </Typography>
        </div>
      }
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_buying_speciality_software_fee').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcSpecialitySoftwareFee())}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_buying_postage_printing').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcPostage())}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
        {remoteConfig.getValue('output_buying_eft_fees').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcWireTransfer())}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_buying_id_veification_fee').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcIDVerificationFee(purchasers))}
        </Typography>
      </div>
      {mortgage &&
        <div className="output-body-container">
          <Typography component="p" variant="inherit">
            {remoteConfig.getValue('output_buying_mortgage_instruction_fee').asString()}
          </Typography>
          <Typography component="p" variant="inherit">
            {currencyFormat(calcMortgageInstructionFee(mortgage))}
          </Typography>
        </div>
      }
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_buying_government_fees').asString()}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_buying_land_title_form_a').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcLandTitleFormA())}
        </Typography>
      </div>
      { calcLandTitleFormB(mortgage) > 0 &&
        <div className="output-body-container">
          <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_buying_land_title_form_b').asString()}
          </Typography>
          <Typography component="p" variant="inherit">
            {currencyFormat(calcLandTitleFormB(mortgage))}
          </Typography>
        </div>
      }
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
        {remoteConfig.getValue('output_buying_land_title_search_fee').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcLandTitleSearchFees())}
        </Typography>
      </div>
        <div className="output-body-container">
          <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_buying_municipal_search_fee').asString()}
          </Typography>
          <Typography component="p" variant="inherit">
            {currencyFormat(calcTaxCertificate(municipality))}
          </Typography>
        </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_buying_trust_administration_fee').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcTrustAdministrationFee())}
        </Typography>
      </div>
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_buying_total_closing_costs').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(
            calcTotalClosingCosts(price, purchasers, municipality, mortgage, strata, newConstruction, firstTimeBuyer)
          )}
        </Typography>
      </div>
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_buying_total_conveyance').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(
            calcPriceOfConveyance(
              price,
              purchasers,
              municipality,
              mortgage,
              strata,
              newConstruction,
              firstTimeBuyer,
            )
          )}
        </Typography>
      </div>
      <div className="flex-container">
        <Button
          onClick={props.handleCalculateAgain}
          variant="contained"
          color="secondary"
        >
          {remoteConfig.getValue('button_pac').asString()}
        </Button>
      </div>
    </div>
  );
};

export default Output;
