import "./App.css";
import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "./components/Header.js";
import Loading from "./components/Loading.js";
import Input from "./components/Input.js";
import Output from "./components/Output.js";
import SellingOutput from "./components/SellingOutput.js";
import RefinanceOutput from "./components/RefinanceOutput.js";
import Confirm from "./components/Confirm.js";
import Contact from "./components/Contact.js";
import firebase from "./helpers/Firestore"

const remoteConfig = firebase.remoteConfig();

const db = firebase.firestore();
const analytics = firebase.analytics();
const timeout = 2000;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: "8px auto",
      width: "80%",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}));

const App = () => {
  const classes = useStyles();
  const [start, setStart] = React.useState(true);
  const [tab, setTab] = React.useState(0);
  const [seenSummary, setSeenSummary] = React.useState(false);
  const [price, setPrice] = React.useState(null);
  const [priceErrorText, setPriceErrorText] = React.useState("");
  const [municipality, setMunicipality] = React.useState("");
  const [purchasers, setPurchasers] = React.useState("1");
  const [mortgage, setMortgage] = React.useState(false);
  const [strata, setStrata] = React.useState(false);
  const [firstTimeBuyer, setFirstTimeBuyer] = React.useState(false);
  const [newConstruction, setNewConstruction] = React.useState(false);

  //Selling
  const [sellingPrice, setSellingPrice] = React.useState(null);
  const [sellingPriceErrorText, setSellingPriceErrorText] = React.useState("");
  const [sellingMunicipality, setSellingMunicipality] = React.useState("");
  const [sellers, setSellers] = React.useState("1");
  const [sellingCharges, setSellingCharges] = React.useState("1");
  const [sellingMortgage, setSellingMortgage] = React.useState(false);

  //Refinance
  const [refinancePrice, setRefinancePrice] = React.useState(null);
  const [refinancePriceErrorText, setRefinancePriceErrorText] = React.useState("");
  const [refinanceMunicipality, setRefinanceMunicipality] = React.useState("");
  const [borrowers, setBorrowers] = React.useState("1");
  const [refinanceCharges, setRefinanceCharges] = React.useState("1");
  const [refinanceStrata, setRefinanceStrata] = React.useState(false);

  //Contact
  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [emailLoading, setEmailLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");

  useEffect(() => {
    const id = getParameterByName("id");
    remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    remoteConfig.fetchAndActivate()
      .then((fetched) => {
        if (fetched) {
          setStart(false);
          if(id == null){
            setScreen("input");
          } 
        } else {
          setStart(false);
          if(id == null){
            setScreen("input");
          } 
        }
      })
      .catch((error) => {
        console.error("Error fetching remote config", error);
      });
  }, []);

  const getParameterByName = (name) => {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  const id = getParameterByName("id");
  const [screen, setScreen] = React.useState(id == null ? "start" : "loading");

  // Buying 
  const handleSetPrice = (value) => {
    setPrice(value);
  };

  // Buying 
  const handleSetTab = (value) => {
    setTab(value);
  };

  const handleSetMunicipality = (event) => {
    setMunicipality(event.target.value);
  };

  const handleSetPurchasers = (event) => {
    setPurchasers(event.target.value);
  };

  const handleSetMortgage = (event) => {
    setMortgage(event.target.checked);
  };

  const handleSetStrata = (event) => {
    setStrata(event.target.checked);
  };

  const handleSetFirstTimeBuyer = (event) => {
    setFirstTimeBuyer(event.target.checked);
  };

  const handleSetNewConstruction = (event) => {
    setNewConstruction(event.target.checked);
  };

  //Selling
  const handleSetSellingPrice = (value) => {
    setSellingPrice(value);
  };

  const handleSetSellingMunicipality = (event) => {
    setSellingMunicipality(event.target.value);
  };

  const handleSetSellers = (event) => {
    setSellers(event.target.value);
  };

  const handleSetSellingCharges = (event) => {
    setSellingCharges(event.target.value);
  };

  const handleSetSellingMortgage = (event) => {
    setSellingMortgage(event.target.checked);
  };

  const handleSellingCalculate = () => {
    if (!sellingPrice) {
      setSellingPriceErrorText("Please enter a selling price");
      return
    }
    setSellingPriceErrorText("");
    uploadCalcSellingToFirebase();
    setScreen("loading");
    setTimeout(() => {
      if (!sellingPrice) {
        setSellingPrice(0);
      }
      setScreen("selling_output");
    }, timeout);
  }

  //////Refinance///////
  const handleSetRefinancePrice = (value) => {
    setRefinancePrice(value);
  };

  const handleSetRefinanceMunicipality = (event) => {
    setRefinanceMunicipality(event.target.value);
  };

  const handleSetBorrowers = (event) => {
    setBorrowers(event.target.value);
  };

  const handleSetRefinanceCharges = (event) => {
    setRefinanceCharges(event.target.value);
  };

  const handleSetRefinanceStrata = (event) => {
    setRefinanceStrata(event.target.checked);
  };

  const handleRefinanceCalculate = (value) => {
    if (!refinancePrice) {
      setRefinancePriceErrorText("Please enter a refinance price");
      return
    }
    setRefinancePriceErrorText("");
    uploadCalcRefinanceToFirebase();
    setScreen("loading");
    setTimeout(() => {
      if (!refinancePrice) {
        setRefinancePrice(0);
      }
      setScreen("refinance_output");
    }, timeout);
  };


  // Contact 
  const handleSetName = (event) => {
    setName(event.target.value);
  };

  const handleSetEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleCalculate = () => {
    if (!price) {
      setPriceErrorText("Please enter a purchase price");
      return
    }
    setPriceErrorText("");
    uploadCalcBuyingToFirebase();
    setScreen("loading");
    setTimeout(() => {
      if (!price) {
        setPrice(0);
      }
      setScreen("output");
    }, timeout);
  }

  const handleCalculateAgain = () => {
    analytics.logEvent('pressed_price_another_conveyance')
    setTab(0);
    setScreen("input");
  };

  const handleSendEmail = () => {
    if (!validEmail(email)){
      setEmailError("Please enter a valid email")
      return
    }
    setEmailError("")
    sendEmail()
  };

  const validEmail = (email) => {
    if (!email) {
      return false
    }
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const clearEmailError = () => {
    setEmailError("")
  }

const uploadCalcBuyingToFirebase = () => {
  analytics.logEvent('pressed_price_my_conveyance');
  db.collection("calc_buying").add({
      price: price,
      municipality: municipality,
      purchasers: purchasers,
      strata: strata,
      mortgage: mortgage,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
  })
}

const uploadCalcSellingToFirebase = () => {
  analytics.logEvent('pressed_price_my_conveyance');
  db.collection("calc_selling").add({
      sellingPrice: sellingPrice,
      sellingMunicipality: sellingMunicipality,
      sellers: sellers,
      sellingCharges: sellingCharges,
      sellingMortgage: sellingMortgage,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
  })
}

const uploadCalcRefinanceToFirebase = () => {
  analytics.logEvent('pressed_price_my_conveyance');
  db.collection("calc_refinance").add({
    refinancePrice: refinancePrice,
    refinanceMunicipality: refinanceMunicipality,
    borrowers: borrowers,
    refinanceCharges: refinanceCharges,
    refinanceStrata: refinanceStrata,
    timestamp: firebase.firestore.FieldValue.serverTimestamp()
  })
}
  const sendEmail = () => {
    analytics.logEvent('pressed_receive_your_price_via_email');
    setEmailLoading(true);
    if(tab == 0){
      uploadContactBuyingToFirebase();
    }else if(tab == 1){
      uploadContactSellingToFirebase();
    }else if(tab == 2){
      uploadContactRefinanceToFirebase();
    }
    setEmailLoading(false)
    setScreen("confirm");
  }

const uploadContactBuyingToFirebase = () => {
  db.collection("contact_buying").add({
    email: email,
    name: name ? name : "", 
    price: price,
    municipality: municipality,
    purchasers: purchasers,
    strata: strata,
    mortgage: mortgage,
    timestamp: firebase.firestore.FieldValue.serverTimestamp()
  });
}

const uploadContactSellingToFirebase = () => {
  db.collection("contact_selling").add({
    email: email,
    name: name ? name : "", 
    sellingPrice: sellingPrice,
    sellingMunicipality: sellingMunicipality,
    sellers: sellers,
    sellingCharges: sellingCharges,
    sellingMortgage: sellingMortgage,
    timestamp: firebase.firestore.FieldValue.serverTimestamp()
  });
}

const uploadContactRefinanceToFirebase = () => {
  db.collection("contact_refinance").add({
    email: email,
    name: name ? name : "", 
    refinancePrice: refinancePrice,
    refinanceMunicipality: refinanceMunicipality,
    borrowers: borrowers,
    refinanceCharges: refinanceCharges,
    refinanceStrata: refinanceStrata,
    timestamp: firebase.firestore.FieldValue.serverTimestamp()
  });
}

  const getFirebaseData = async () => {
    if (id != null && !seenSummary) {
      // Define the collection names you want to search in
      const collectionNames = ["contact_buying", "contact_selling", "contact_refinance"];
  
      // Iterate through each collection and try to fetch the data
      for (const collectionName of collectionNames) {
        try {
          const docRef = db.collection(collectionName).doc(id);
          const docSnapshot = await docRef.get();
  
          if (docSnapshot.exists) {
            const data = docSnapshot.data();
            // Data found in this collection, return it
            if(collectionName == "contact_buying"){
              // TODO
              setPrice(data.price);
              setMunicipality(data.municipality);
              setPurchasers(data.purchasers);
              setStrata(data.strata);
              setMortgage(data.mortgage);

              setSeenSummary(true);
              setScreen("output");
              return;

            }else if(collectionName == "contact_selling"){
              setSellingPrice(data.sellingPrice);
              setSellingMunicipality(data.sellingMunicipality);
              setSellers(data.sellers);
              setStrata(data.strata);
              setSellingCharges(data.sellingCharges);
              setSellingMortgage(data.sellingMortgage);

              setSeenSummary(true);
              setScreen("selling_output");
              return;

            }else if(collectionName == "contact_refinance"){
              setRefinancePrice(data.refinancePrice);
              setRefinanceMunicipality(data.refinanceMunicipality);
              setBorrowers(data.borrowers);
              setRefinanceCharges(data.refinanceCharges);
              setRefinanceStrata(data.refinanceStrata);

              setSeenSummary(true);
              setScreen("refinance_output");
              return;

            }
          }
        } catch (error) {
          console.error(`Error fetching data from ${collectionName}:`, error);
        }
      }
  
      // If the loop completes without finding the data, you can return an error message or handle it as needed.
      console.error(`Data with id ${id} not found in any collection.`);
      return null; // or throw an error
    }
  };

  getFirebaseData();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <CssBaseline />
      {screen === "start" ? <div></div> :
      <div className="app">
        <Header />
        <div className="container">
        
          {screen === "input" && (
            <Input
              setTab={handleSetTab}
              price={price}
              handleSetPrice={handleSetPrice}
              priceErrorText={priceErrorText}
              municipality={municipality}
              handleSetMunicipality={handleSetMunicipality}
              purchasers={purchasers}
              handleSetPurchasers={handleSetPurchasers}
              mortgage={mortgage}
              handleSetMortgage={handleSetMortgage}
              strata={strata}
              handleSetStrata={handleSetStrata}
              firstTimeBuyer={firstTimeBuyer}
              handleSetFirstTimeBuyer={handleSetFirstTimeBuyer}
              newConstruction={newConstruction}
              handleSetNewConstruction={handleSetNewConstruction}
              handleCalculate={handleCalculate}

              sellingPrice={sellingPrice}
              handleSetSellingPrice={handleSetSellingPrice}
              sellingPriceErrorText={sellingPriceErrorText}
              sellingMunicipality={sellingMunicipality}
              handleSetSellingMunicipality={handleSetSellingMunicipality}
              handleSetSellers={handleSetSellers}
              sellers={sellers}
              handleSetSellingMortgage={handleSetSellingMortgage}
              sellingMortgage={sellingMortgage}
              handleSetSellingCharges={handleSetSellingCharges}
              sellingCharges={sellingCharges}
              handleSellingCalculate={handleSellingCalculate}

              refinancePrice={refinancePrice}
              handleSetRefinancePrice={handleSetRefinancePrice}
              refinancePriceErrorText={refinancePriceErrorText}
              refinanceMunicipality={refinanceMunicipality}
              handleSetRefinanceMunicipality={handleSetRefinanceMunicipality}
              borrowers={borrowers}
              handleSetBorrowers={handleSetBorrowers}
              refinanceCharges={refinanceCharges}
              handleSetRefinanceCharges={handleSetRefinanceCharges}
              handleSetRefinanceStrata={handleSetRefinanceStrata}
              refinanceStrata={refinanceStrata}
              handleRefinanceCalculate={handleRefinanceCalculate}
            />
          )}
          {screen === "output" && (
            <div>
            <Output
              price={price}
              municipality={municipality}
              purchasers={purchasers}
              mortgage={mortgage}
              strata={strata}
              firstTimeBuyer={firstTimeBuyer}
              newConstruction={newConstruction}
              handleCalculateAgain={handleCalculateAgain}
            />
            <Contact 
              name={name}
              handleSetName={handleSetName}
              email={email}
              handleSetEmail={handleSetEmail}
              emailError={emailError}
              handleSendEmail={handleSendEmail}
              emailLoading={emailLoading}
              clearEmailError={clearEmailError}
            />
            </div>
          )}
          {screen === "selling_output" && (
            <div>
            <SellingOutput
              price={sellingPrice}
              sellers={sellers}
              sellingCharges={sellingCharges}
              sellingMortgage={sellingMortgage}
              handleCalculateAgain={handleCalculateAgain}
            />
            <Contact 
              name={name}
              handleSetName={handleSetName}
              email={email}
              handleSetEmail={handleSetEmail}
              emailError={emailError}
              handleSendEmail={handleSendEmail}
              emailLoading={emailLoading}
              clearEmailError={clearEmailError}
            />
            </div>
          )}
          {screen === "refinance_output" && (
            <div>
            <RefinanceOutput
              price={refinancePrice}
              borrowers={borrowers}
              refinanceMunicipality={refinanceMunicipality}
              refinanceCharges={refinanceCharges}
              refinanceStrata={refinanceStrata}
              handleCalculateAgain={handleCalculateAgain}
            />
            <Contact 
              name={name}
              handleSetName={handleSetName}
              email={email}
              handleSetEmail={handleSetEmail}
              emailError={emailError}
              handleSendEmail={handleSendEmail}
              emailLoading={emailLoading}
              clearEmailError={clearEmailError}
            />
            </div>
          )}
          {screen === "loading" && <Loading />}
          {screen === "confirm" && 
            <Confirm 
              handleCalculateAgain={handleCalculateAgain}
            />
          }
        </div>
      </div>}
    </form>
  );
};

export default App;
