import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  calcRefinanceLegalFees,
  calcRefinanceEFTFees,
  calcRefinancePostage,
  calcRefinanceTotal,
  calcStrataFormsFee,
  calcTitleInsurance,
  calcSpecialitySoftwareFee,
  calcIDVerificationFee,
  calcTaxCertificate,
  calcRefinanceMortgageInstructionFee,
  calcRefinanceLandTitleFormB,
  calcLandTitleSearchFees,
  rangeFormat,
  currencyFormat,
} from "../helpers/Calculations.js";
import firebase from "./../helpers/Firestore";

const remoteConfig = firebase.remoteConfig();

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: "bold",
  },
}));

const RefinanceOutput = (props) => {
  const classes = useStyles();
  const { price, borrowers, refinanceCharges, refinanceStrata, refinanceMunicipality } = props;
  return (
    <div className="container">
      {/* Insurance */}
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_refinance_legal_fees').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcRefinanceLegalFees(price, borrowers, refinanceCharges, refinanceStrata))}
        </Typography>
      </div>
      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_refinance_disbursements').asString()}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_refinance_title_insurance').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcTitleInsurance(price))}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_refinance_eft_fees').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcRefinanceEFTFees())}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_refinance_speciality_software_fee').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcSpecialitySoftwareFee())}
        </Typography>
      </div>
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_refinance_postage_printing').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcRefinancePostage(refinanceCharges))}
        </Typography>
      </div>
      {refinanceStrata &&
        <div className="output-body-container">
          <Typography component="p" variant="inherit">
            {remoteConfig.getValue('output_refinance_strata_forms_fee').asString()}
          </Typography>
          <Typography component="p" variant="inherit">
            {rangeFormat(calcStrataFormsFee(refinanceStrata))}
          </Typography>
        </div>
      }
      <div className="output-body-container">
        <Typography component="p" variant="inherit">
        {remoteConfig.getValue('output_refinance_id_verification_fee').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcIDVerificationFee(borrowers))}
        </Typography>
      </div>

      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_refinance_mortgage_instruction_fee').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {currencyFormat(calcRefinanceMortgageInstructionFee())}
        </Typography>
      </div>

      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_refinance_government_fees').asString()}
        </Typography>
      </div>

      <div className="output-body-container">
        <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_refinance_land_title_search_fees').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcLandTitleSearchFees())}
        </Typography>
      </div>

        <div className="output-body-container">
          <Typography component="p" variant="inherit">
          {remoteConfig.getValue('output_refinance_municipal_search_fee').asString()}
          </Typography>
          <Typography component="p" variant="inherit">
            {currencyFormat(calcTaxCertificate(refinanceMunicipality))}
          </Typography>
        </div>

      <div className="output-body-container">
          <Typography component="p" variant="inherit">
            {remoteConfig.getValue('output_refinance_land_title_form_b').asString()}
          </Typography>
          <Typography component="p" variant="inherit">
            {currencyFormat(calcRefinanceLandTitleFormB())}
          </Typography>
        </div>

      <div className="output-header-container">
        <Typography
          className={classes.boldText}
          component="p"
          variant="inherit"
        >
          {remoteConfig.getValue('output_refinance_total_price_of_conveyance').asString()}
        </Typography>
        <Typography component="p" variant="inherit">
          {rangeFormat(calcRefinanceTotal(price, borrowers, refinanceCharges, refinanceMunicipality, refinanceStrata))}
        </Typography>
      </div>
      <div className="flex-container">
        <Button
          onClick={props.handleCalculateAgain}
          variant="contained"
          color="secondary"
        >
          {remoteConfig.getValue('button_pac').asString()}
        </Button>
      </div>
    </div>
  );
};

export default RefinanceOutput;