import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import firebase from "./../helpers/Firestore";

const remoteConfig = firebase.remoteConfig();

const Loading = (props) => {
  return (
    <div className="loading-container">
      <CircularProgress color="primary" />
      <div className="spacing">{remoteConfig.getValue('loading_text').asString()}</div>
    </div>
  );
};

export default Loading;
