import { municipalities } from "./Municipalities.js";
import firebase from "./../helpers/Firestore";

const remoteConfig = firebase.remoteConfig();

// Constants
var hst = 1.12;
var gst = 0.05;
var legalBaseFee = 950;
var refinanceLegalBaseFee = 900;
var insuranceBinder = [25,75];
var strataFormsFee = [75,150];
var specialitySoftwareFee = 105;
var postage = [25,75];
var wireTransfer = [25,50];
var landTitleFormA = 85;
var landTitleFormB = 85;
var mortgageInstructionFee = 35;
var landTitleSearchFees = [50,150];
var trustAdministrationFee = 15.75;

///Selling Constances
var sellingLandTitleSearchFees = [30,50];

remoteConfig.settings.minimumFetchIntervalMillis = 1000;
remoteConfig.fetchAndActivate()
.then((fetched) => {
  hst = remoteConfig.getValue('hst').asNumber();
  gst = remoteConfig.getValue('gst').asNumber();
  legalBaseFee = remoteConfig.getValue('legal_base_fee').asNumber();
  refinanceLegalBaseFee = remoteConfig.getValue('refinance_legal_base_fee').asNumber();
  insuranceBinder = JSON.parse(remoteConfig.getValue('insurance_binder').asString());
  strataFormsFee = JSON.parse(remoteConfig.getValue('strata_forms_fee').asString());
  specialitySoftwareFee = remoteConfig.getValue('speciality_software_fee').asNumber();
  postage = JSON.parse(remoteConfig.getValue('postage').asString());
  wireTransfer = JSON.parse(remoteConfig.getValue('wire_transfer').asString());
  landTitleFormA = remoteConfig.getValue('land_title_form_a').asNumber();
  landTitleFormB = remoteConfig.getValue('land_title_form_b').asNumber();
  mortgageInstructionFee = remoteConfig.getValue('mortgage_instruction_fee').asNumber();
  landTitleSearchFees = JSON.parse(remoteConfig.getValue('land_title_search_fees').asString());
  trustAdministrationFee = remoteConfig.getValue('trust_administration_fee').asNumber();
  sellingLandTitleSearchFees = JSON.parse(remoteConfig.getValue('selling_land_title_search_fees').asString());
})
.catch((error) => {
  console.error("Error fetching remote config", error);
});


// Calculations
export const calcGST = (price, newConstruction) => {
  if(newConstruction){
    return price * gst
  }
  return 0
}

export const calcPTT = (price, firstTimeBuyer, newConstruction) => {
  if (!price) return 0;

  let ptt = 0;
  if (price < 200000) {
    ptt = price * 0.01;
  } else if (price < 2000000) {
    ptt = 2000 + (price - 200000) * 0.02;
  } else if (price < 3000000) {
    ptt = 38000 + (price - 2000000) * 0.03;
  } else {
    ptt = 68000 + (price - 3000000) * 0.05;
  }

  const calculateNewConstructionDiscount = (price, ptt, threshold, margin, max) => {
    if (price <= threshold) {
      return 0;
    }
  
    const upperLimit = threshold + margin;
    let tmpPrice = price > upperLimit ? upperLimit : price;

    var ratio = ((upperLimit - tmpPrice) / margin);

    var exception = ptt * ratio;
  
    return ptt - exception;
  };

  const calculateFirstTimeBuyerDiscount = (price, ptt, threshold, margin, max) => {
    if (price <= threshold) {
      ptt = ptt - max;
      return ptt <= 0 ? 0 : ptt;
    }
  
    const upperLimit = threshold + margin;
    let tmpPrice = price > upperLimit ? upperLimit : price;

    var ratio = ((upperLimit - tmpPrice) / margin);

    var exception = max * ratio;
  
    return ptt - exception;
  };

  const newConstructionThreshold = 1100000;
  const firstTimeBuyerThreshold = 835000;

  if (newConstruction) {
    return calculateNewConstructionDiscount(price, ptt, newConstructionThreshold, 50000, 13000);
  }

  if (firstTimeBuyer) {
    return calculateFirstTimeBuyerDiscount(price, ptt, firstTimeBuyerThreshold, 25000, 8000);
  }

  return ptt;
};


export const calcLegalFees = (price, purchasers, mortgage, strata) => {
  return legalBaseFee + calcComplexityUnit(price, purchasers, mortgage, strata) * hst;
};

export const calcComplexityUnit = (price, purchasers, mortgage, strata) => {
  var complexityUnits = 0;
  if (purchasers === "3+") {
    complexityUnits += 100;
  }
  if (mortgage) {
    complexityUnits += 150;
  }
  if (strata) {
    complexityUnits += 50;
  }
  price = price - 1000000
  if (price > 0){
    complexityUnits += 50 + Math.round(price/2000)
  }
  return complexityUnits;
};

export const calcTitleInsurance = (price) => {
  if(price < 1000000){
    return [150,250]
  }else if(price < 2000000){
    return [200,300]
  }else if(price < 4000000){
    return [250,500]
  }else{
    return [500,1000];
  }
};

export const calcInsuranceBinder = () => {
  return insuranceBinder;
};

export const calcStrataFormsFee = (strata) => {
  if (strata) {
    return strataFormsFee;
  }
  return [0, 0];
};

export const calcMortgageInstructionFee = (mortgage) => {
  return mortgage ? mortgageInstructionFee : 0
};

export const calcSpecialitySoftwareFee = () => {
  return specialitySoftwareFee;
};

export const calcPostage = () => {
  return postage;
};

export const calcWireTransfer = () => {
  return wireTransfer;
};

export const calcLandTitleFormA = () => {
  return landTitleFormA;
};

export const calcLandTitleFormB = (mortgage) => {
  if (mortgage) {
    return landTitleFormB;
  }
  return 0;
};

export const calcLandTitleSearchFees = () => {
  return landTitleSearchFees;
};

export const calcTaxCertificate = (municipality) => {
  const found = municipalities.find((city) => city.name === municipality);
  return typeof found === "undefined" ? 0 : found.fees;
};

export const calcTrustAdministrationFee = () => {
  return trustAdministrationFee;
};

export const calcIDVerificationFee = (persons) => {
  if (persons === "1"){
    return 15
  }else if(persons === "2"){
    return 30
  }
  return 45
};

export const calcTotalClosingCosts = (
  price,
  purchasers,
  municipality,
  mortgage,
  strata,
  newConstruction,
  firstTimeBuyer
) => {
  const total = [0, 0];

  total[0] += calcLegalFees(price, purchasers, mortgage, strata);
  total[1] += calcLegalFees(price, purchasers, mortgage, strata);

  total[0] += calcTitleInsurance(price)[0];
  total[1] += calcTitleInsurance(price)[1];

  total[0] += calcInsuranceBinder()[0];
  total[1] += calcInsuranceBinder()[1];

  total[0] += calcStrataFormsFee(strata)[0];
  total[1] += calcStrataFormsFee(strata)[1];

  total[0] += calcSpecialitySoftwareFee();
  total[1] += calcSpecialitySoftwareFee();

  total[0] += calcPostage()[0];
  total[1] += calcPostage()[1];

  total[0] += calcWireTransfer()[0];
  total[1] += calcWireTransfer()[1];

  total[0] += calcLandTitleFormA();
  total[1] += calcLandTitleFormA();

  total[0] += calcLandTitleFormB(mortgage);
  total[1] += calcLandTitleFormB(mortgage);

  total[0] += calcLandTitleSearchFees()[0];
  total[1] += calcLandTitleSearchFees()[1];

  total[0] += calcTaxCertificate(municipality);
  total[1] += calcTaxCertificate(municipality);

  total[0] += calcTrustAdministrationFee();
  total[1] += calcTrustAdministrationFee();

  total[0] += calcIDVerificationFee(purchasers);
  total[1] += calcIDVerificationFee(purchasers);

  total[0] += calcMortgageInstructionFee(mortgage);
  total[1] += calcMortgageInstructionFee(mortgage);

  total[0] += calcPTT(price, firstTimeBuyer, newConstruction);
  total[1] += calcPTT(price, firstTimeBuyer, newConstruction);

  total[0] += calcGST(price, newConstruction)
  total[1] += calcGST(price, newConstruction)

  return total;
};

export const calcPriceOfConveyance = (
  price,
  purchasers,
  municipality,
  mortgage,
  strata,
  newConstruction,
  firstTimeBuyer,
) => {
  const total = [0, 0];

  total[0] += price;
  total[1] += price;

  total[0] += calcTotalClosingCosts(price, purchasers, municipality, mortgage, strata, newConstruction, firstTimeBuyer)[0];
  total[1] += calcTotalClosingCosts(price, purchasers, municipality, mortgage, strata, newConstruction, firstTimeBuyer)[1];

  return total;
};

////////// Selling
export const calcSellingLegalFees = (price, sellers, sellerCharges, sellingMortgage) => {
  var base = legalBaseFee;
  if (sellers > 1){
    base += (sellers - 1) * 100
  }
  if (sellerCharges > 1){
    base += (sellerCharges - 1) * 100
  }
  if (sellingMortgage){
    base += 100
  }
  if (price <= 1000000){
    return base
  }
  var adjPrice = price - 1000000 
  var tick = Math.floor(adjPrice / 100000)
  return base + (tick * 25)
}

export const calcSellingPostage = (charges, mortgage) => {
  const mortgageFee = mortgage ? 50 : 0
  if(charges > 1){
    return (charges * 50) + mortgageFee
  }
  return 50 + mortgageFee
}

export const calcSellingEFTFees = () => {
  return wireTransfer
}

export const calcSellingLandTitleSearchFees = () => {
  return sellingLandTitleSearchFees;
};

export const calcSellingTotal = (price, sellers, sellerCharges, sellingMortgage) => {
  const total = [0, 0];

  total[0] += calcSellingLegalFees(price, sellers, sellerCharges, sellingMortgage);
  total[1] += calcSellingLegalFees(price, sellers, sellerCharges, sellingMortgage);

  total[0] += calcSellingPostage(sellerCharges, sellingMortgage);
  total[1] += calcSellingPostage(sellerCharges, sellingMortgage);

  total[0] += calcSellingEFTFees()[0];
  total[1] += calcSellingEFTFees()[1];

  total[0] += calcIDVerificationFee(sellers);
  total[1] += calcIDVerificationFee(sellers);

  total[0] += calcSellingLandTitleSearchFees()[0];
  total[1] += calcSellingLandTitleSearchFees()[1];

  return total;
}

/// Refinance
export const calcRefinanceEFTFees = () => {
  return wireTransfer
}

export const calcRefinanceLegalFees = (price, borrowers, refinanceCharges, refinanceStrata) => {
  var base = refinanceLegalBaseFee;
  if (borrowers > 1){
    base += (borrowers - 1) * 100
  }
  if (refinanceCharges > 1){
    base += (refinanceCharges - 1) * 100
  }
  if (refinanceStrata) {
    base += 50
  }
  if (price <= 500000){
    return base
  }
  var adjPrice = price - 500000 
  var tick = Math.floor(adjPrice / 100000)
  return base + (tick * 25)
}

export const calcRefinancePostage = (charges) => {
  if(charges > 1){
    return charges * 50
  }
  return 50
}

export const calcRefinanceLandTitleFormB = () => {
  return landTitleFormB;
};

export const calcRefinanceMortgageInstructionFee = () => {
  return mortgageInstructionFee;
};

export const calcRefinanceTotal = (price, borrowers, refinanceCharges, refinanceMunicipality, refinanceStrata) => {
  const total = [0, 0];

  total[0] += calcLandTitleSearchFees()[0];
  total[1] += calcLandTitleSearchFees()[1];

  total[0] += calcRefinanceLegalFees(price, borrowers, refinanceCharges, refinanceStrata);
  total[1] += calcRefinanceLegalFees(price, borrowers, refinanceCharges, refinanceStrata);

  total[0] += calcRefinanceEFTFees(refinanceCharges)[0];
  total[1] += calcRefinanceEFTFees(refinanceCharges)[1];

  total[0] += calcRefinancePostage(refinanceCharges);
  total[1] += calcRefinancePostage(refinanceCharges);

  total[0] += calcIDVerificationFee(borrowers);
  total[1] += calcIDVerificationFee(borrowers);

  total[0] += calcTaxCertificate(refinanceMunicipality);
  total[1] += calcTaxCertificate(refinanceMunicipality);

  total[0] += calcRefinanceMortgageInstructionFee();
  total[1] += calcRefinanceMortgageInstructionFee();

  total[0] += calcRefinanceLandTitleFormB();
  total[1] += calcRefinanceLandTitleFormB();

  total[0] += calcStrataFormsFee(refinanceStrata)[0];
  total[1] += calcStrataFormsFee(refinanceStrata)[1];

  return total;
}

// Formatter
export const rangeFormat = (range) => {
  return `${currencyFormat(range[0])} - ${currencyFormat(range[1])}`;
};

export const currencyFormat = (num) => {
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
